import { convertToLabelValue } from "./convertToLabelValue"
import { generateInitialValues } from "../../../services/context"
import { getSignedInUser } from "../../Auth/services/user"

import { isBrowser } from "../../../services/general"

export const sourceProfileFormSchema = ({ formFields, moduleName, state }) => {
  let initialValues = {}
  let userData = {}
  let address = state?.address || {}

  if (isBrowser()) {
    switch (moduleName) {
      case "edit":
        userData = getSignedInUser()?.userData

        if (userData.birthday) {
          userData.birthday.year = userData.birthday.year?.label
            ? userData.birthday.year
            : convertToLabelValue(userData?.birthday, "year")
          userData.birthday.date = userData.birthday.date?.label
            ? userData.birthday.date
            : convertToLabelValue(userData?.birthday, "date")
          userData.birthday.month = userData.birthday.month?.label
            ? userData.birthday.month
            : convertToLabelValue(userData?.birthday, "month")
        }
        userData.emergencyContactRelationship = userData?.emergencyContact
          ?.relationship
          ? convertToLabelValue(userData?.emergencyContact, "relationship")
          : { label: "", value: "" }

        userData.emergencyContactName =
          userData?.emergencyContact?.name || userData.emergencyContactName

        userData.emergencyContactNumber =
          userData?.emergencyContact?.number || userData.emergencyContactNumber

        if (userData.site)
          userData.site = userData?.site?.label
            ? userData?.site?.label
            : userData.site

        formFields = formFields.filter(
          (field) =>
            field.name !== "-" &&
            field.name !== "detailsCorrect" &&
            field.name !== "termsAndConditions" &&
            field.name !== "address"
        )
        initialValues = {
          ...generateInitialValues({
            fields: formFields,
          }),
          ...userData,
          emergencyContactName:
            userData?.emergencyContact?.name || userData?.emergencyContactName,
          emergencyContactRelationship:
            userData?.emergencyContact?.relationship ||
            userData?.emergencyContactRelationship,
          emergencyContactNumber:
            userData?.emergencyContact?.mobileNumber ||
            userData?.emergencyContactNumber,
        }

        break
      case "add-address":
        formFields = formFields.filter((field) => field.name === "address")
        initialValues = {
          ...generateInitialValues({
            fields: formFields,
          }),
        }
        break
      case "edit-address":
        // renders twice idk why
        if (!address.city.value) {
          address.city = convertToLabelValue(address, "city")
          address.province = convertToLabelValue(address, "province")
          address.barangay = convertToLabelValue(address, "barangay")
        }

        formFields = formFields.filter((field) => field.name === "address")
        initialValues = {
          ...generateInitialValues({
            fields: formFields,
          }),
          address,
        }
        break
      case "update-password":
        initialValues = {
          ...generateInitialValues({
            fields: formFields,
          }),
        }
        break
      default:
        formFields = []
    }
  }

  return { formFields, initialValues }
}
